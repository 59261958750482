import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { select, Store } from '@ngrx/store';
import { Subject, take, takeUntil } from 'rxjs';
import {
	SkillStatusEnum,
	UserSavedSkillInterface,
} from 'src/app/modules/user-profile/_models/profile-skills.interfaces';
import { updateSkill } from 'src/app/modules/user-profile/_state/user-profile.actions';
import {
	getSkillDetailsById,
	UserProfileState,
} from 'src/app/modules/user-profile/_state/user-profile.reducer';

import { MatDialog } from '@angular/material/dialog';
import { AddCommentPopupComponent } from '../add-comment-popup/add-comment-popup.component';
import { AddSkillCertificatePopupComponent } from '../add-skill-certificate-popup/add-skill-certificate-popup.component';
import moment from 'moment';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { SkillsService } from 'src/app/modules/skills-and-credentials/_services/skills.service';
import { SkillAchievementParams } from 'src/app/modules/skills-and-credentials/_models/skilled-users.model';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserProfileService } from 'src/app/modules/user-profile/_services/user-profile.service';
import { BroadcastService } from 'src/app/core/services/broadcast.service';

@Component({
	selector: '[skill-entry]',
	templateUrl: './skill-entry.component.html',
	styleUrls: ['./skill-entry.component.scss'],
})
export class SkillEntryComponent implements OnInit, OnDestroy {
	@Input() skillId: number;
	@Input() renewalType: string;
	@Input() name: string;
	@Input() datesOverridesAllowed: boolean;
	@Input() userId: number;
	@Input() description: string;
	@Input() isManagedUsers: boolean;
	@Input() hasCourseAttached: boolean;
	@Input() public useComp: boolean;
	@Input() public defaultRenewalFrequency: number;
	@Input() public newRenewalFrequencyInYears: number;
	@Input() public defaultAlertsThresholdInDays: number;
	@Input() public hasCertificate: boolean;
	@Input() public hasComment: boolean;
	public skillForm: FormGroup;
	public skill: UserSavedSkillInterface;
	public skillStatus: string = '';
	public skillStatusClass: string = '';

	public showForm: boolean = false;

	public competenceData = [
		{ id: 1, name: 'Somewhat Competent', data: { selected: false } },
		{ id: 2, name: 'Competent', data: { selected: false } },
		{ id: 3, name: 'Very Competent', data: { selected: false } },
	];

	@Output() onSelectionChange = new EventEmitter<[boolean, number]>();

	private unsubscriber$ = new Subject<void>();
	private formInitialised: boolean = false;

	constructor(
		private profileService: UserProfileService,
		private profileStore: Store<UserProfileState>,
		private notificationService: NotificationService,
		private skillService: SkillsService,
		private _fb: FormBuilder,
		private matDialog: MatDialog,
		private broadcastService: BroadcastService
	) {}

	ngOnInit(): void {
		this.initSkillForm();
		this.checkForChanges();
	}

	private initSkillForm(): void {
		this.skillForm = this._fb.group({
			competency: new FormControl(),
			obtainedDate: new FormControl(),
			renewalDate: new FormControl(),
			alertDate: new FormControl(),
		});
		this.profileStore
			.pipe(
				select(getSkillDetailsById(this.skillId)),
				takeUntil(this.unsubscriber$)
			)
			.subscribe({
				next: (res) => {
					if (!res) {
						this.showForm = false;
						this.formInitialised = false;
						return;
					}

					this.skill = res;
					this.showForm = true;
					this.skillStatus = this.skill.status;
					this.skillStatusClass =
						this.skillStatus &&
						this.skillStatus.toLowerCase().split(' ').join('-');
					if (
						!this.skillForm.get('competency').value &&
						!this.formInitialised
					) {
						this.initFormData();
						this.formInitialised = true;
					}
				},
			});
	}

	private initFormData(): void {
		/**
		 * BUG: if the compethency value is not set, this goes into infinite loop.
		 */

		const skillCompetenceLevelId = this.getCompetenceLevel();
		this.skillForm.get('competency').setValue(skillCompetenceLevelId);
		this.competenceData.forEach(
			(x) => (x.data.selected = skillCompetenceLevelId == x.id)
		);
		this.skillForm
			.get('obtainedDate')
			.setValue(
				GenericHelper.getDateFromString(this.skill.obatainedDate)
			);
		this.skillForm
			.get('renewalDate')
			.setValue(GenericHelper.getDateFromString(this.skill.renewalDate));
		this.skillForm
			.get('alertDate')
			.setValue(GenericHelper.getDateFromString(this.skill.alertDate));
		if (!this.datesOverridesAllowed) {
			this.skillForm.get('obtainedDate').disable();
			this.skillForm.get('renewalDate').disable();
			this.skillForm.get('alertDate').disable();
		} else {
			!this.isManagedUsers && this.skillForm.get('renewalDate').disable();
			!this.isManagedUsers && this.skillForm.get('alertDate').disable();
		}
	}

	private getCompetenceLevel() {
		if (!this.useComp) {
			return 0;
		}
		return this.skill.skillCompetenceLevelId ?? 1;
	}

	private checkForChanges(): void {
		this.skillForm
			.get('obtainedDate')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					const renewalDate = this.getRenewalDate(res);
					this.skillForm.get('renewalDate').setValue(renewalDate);
					this.modifySkillinStore({
						obatainedDate: this.getFormattedDate(res),
					});
				},
			});
		this.skillForm
			.get('renewalDate')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					const alertDate = this.getAlertDate(res);
					this.skillForm.get('alertDate').setValue(alertDate);
					this.modifySkillinStore({
						renewalDate: this.getFormattedDate(res),
					});
				},
			});
		this.skillForm
			.get('alertDate')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res: any) => {
					this.modifySkillinStore({
						alertDate: this.getFormattedDate(res),
					});
				},
			});
		this.skillForm
			.get('competency')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res: any) => {
					const newCompetency = this.competenceData.find(
						(x) => x.name == res
					);
					this.modifySkillinStore({
						skillCompetenceLevelId:
							this.competenceData.find((x) => x.name == res)
								?.id ?? this.skill.skillCompetenceLevelId,
					});
				},
			});
	}

	private getFormattedDate(date: any) {
		return date.format('DD-MM-YYYY');
	}

	private getRenewalDate(obtainedDate: any) {
		return moment(obtainedDate).add(this.defaultRenewalFrequency, 'year');
	}

	private getAlertDate(renewalDate: any) {
		return moment(renewalDate).subtract(
			this.defaultAlertsThresholdInDays,
			'd'
		);
	}

	private modifySkillinStore(skill: Partial<UserSavedSkillInterface>) {
		this.skill = {
			...this.skill,
			...skill,
			isUpdated: !this.skill.isTemp,
		};
		this.profileStore.dispatch(
			updateSkill({ id: this.skillId, skill: this.skill })
		);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
	public change(event: MatCheckboxChange): void {
		this.onSelectionChange.emit([event.checked, this.skillId]);
	}

	public addComment(): void {
		this.skillService
			.getUserSkillComments(this.getSkillItemQueryParams())
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					this.matDialog
						.open(AddCommentPopupComponent, {
							width:
								this.broadcastService.screenSize.value ===
								'small'
									? '100%'
									: '60%',
							data: {
								...this.getSkillItemQueryParams(),
								comments: res[0]?.userComments || [],
							},
						})
						.afterClosed()
						.pipe(takeUntil(this.unsubscriber$))
						.subscribe({
							next: (res) => {
								this.hasComment =
									res && res.numberOfComments > 0;
							},
						});
				},
				error: (err) => this.notificationService.error(err),
			});
	}

	public addCertificate(): void {
		this.skillService
			.getUserSkillCertificates(this.getSkillItemQueryParams())
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					this.matDialog
						.open(AddSkillCertificatePopupComponent, {
							width:
								this.broadcastService.screenSize.value ===
								'small'
									? '100%'
									: '60%',
							data: {
								...this.getSkillItemQueryParams(),
								certificates: res[0]?.userCertificates || [],
								uploadAllowed:
									res[0].allowUserToUpload == 1
										? true
										: false,
							},
						})
						.afterClosed()
						.pipe(takeUntil(this.unsubscriber$))
						.subscribe({
							next: (res) => {
								this.hasCertificate =
									res && res.numberOfCertificates > 0;
							},
						});
				},
				error: (err) => this.notificationService.error(err),
			});
	}

	public onApproveSkill(): void {
		if (!this.isManagedUsers) return;
		if (this.skill.previousSkillStatus) {
			this.updateSkillApprovalStatus(
				this.skill.previousSkillStatus,
				undefined
			);
			return;
		}
		switch (this.skill.status) {
			case SkillStatusEnum.approved.toString():
				/**
				 * Call api and check if course completed or
				 * not and accordingly change status
				 * courseCompelted ? Pending Course Completion : Pending Approval
				 */
				this.courseCompletionStatus();
				break;
			case SkillStatusEnum.pendingCourse.toString():
				/**
				 * Directly send to approved
				 * SLR-2042 - dont change status if in pending course completion
				 */
				break;
			case SkillStatusEnum.pendingApproval.toString():
				/**
				 * Directly send to approved
				 */
				this.updateSkillApprovalStatus(
					SkillStatusEnum.approved.toString(),
					SkillStatusEnum.pendingApproval.toString()
				);
				break;
		}
	}

	private courseCompletionStatus(): void {
		this.profileService
			.getCourseCompletionStatus(this.skill.courseId, this.skill.userId)
			.subscribe({
				next: (res) => {
					let nextStatus = '';
					if (res?.error?.status == 404) {
						nextStatus = SkillStatusEnum.pendingApproval.toString();
					} else if (res['data'] && res['data'][0].isCompleted) {
						nextStatus = SkillStatusEnum.pendingApproval.toString();
					} else {
						nextStatus = SkillStatusEnum.pendingCourse.toString();
					}
					this.updateSkillApprovalStatus(
						nextStatus,
						SkillStatusEnum.approved.toString()
					);
				},
			});
	}

	private updateSkillApprovalStatus(
		status: string,
		previousSkillStatus?: string
	): void {
		const skill: UserSavedSkillInterface = {
			...this.skill,
			status,
			isUpdated: !this.skill.isTemp,
			previousSkillStatus: previousSkillStatus,
		};
		this.profileStore.dispatch(
			updateSkill({ id: this.skillId, skill: skill })
		);
	}
	private getSkillItemQueryParams(): SkillAchievementParams {
		return {
			skillId: this.skillId,
			userId: this.userId,
		};
	}
}
