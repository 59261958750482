<button mat-icon-button>
	<mat-icon
		#pattern
		(mouseover)="showMessage(requirements)"
		(mouseout)="closeMessage()"
		>info</mat-icon
	>
</button>

<ng-template #requirements>
	<div [innerHtml]="passwordTooltipText"></div>
</ng-template>
