import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { HelpSystemService } from 'src/app/core/services/help-system.service';
import { LocalDataService } from 'src/app/core/services/local-data.service';

interface IBreadCrumb {
	title: string;
	url: string;
	queryParams: string;
}

@Component({
	selector: 'app-help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements AfterViewInit {
	public searchHelpText = '';
	public showHelpSystem: boolean;
	public breadCrumbItems = new Array<IBreadCrumb>();
	public articles = [];
	public articleToShow;
	public articleAttachments = [];
	public articleToShowHtml: Document;
	public routeLabels = [];
	public loadingArticle: boolean = false;
	public htmlString = '';
	private readonly unsubscriber$ = new Subject<void>();
	public isSearchEventTriggered: boolean = false;

	constructor(
		private readonly helpService: HelpSystemService,
		private readonly localData: LocalDataService,
		private readonly broadcastService: BroadcastService,
		private readonly route: Router
	) {}

	ngAfterViewInit(): void {
		this.htmlString = '';
		this.localData.getRouteChange().subscribe((event) => {
			this.closeHelpSystem();
		});
		this.checkHelpBroadcast();
	}

	checkHelpBroadcast() {
		this.broadcastService.showHelpSystem$.subscribe((val) => {
			this.showHelpSystem = val;
			if (this.showHelpSystem) {
				this.setRouteLabels();
				this.initContextualHelp();
			}
		});
	}

	initContextualHelp() {
		if (this.localData.modalHeader) {
			this.routeLabels.push(
				this.localData.modalHeader.split(' ').join('_')
			);
		}
		this.getHelpArticlesBasedOnLabel();
	}

	getHelpArticlesBasedOnLabel() {
		let routeString = this.routeLabels.join(',');
		this.helpService
			.getArticleBasedOnLabels(routeString)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((data: any) => {
				this.loadingArticle = false;
				this.isSearchEventTriggered = false;
				this.articleToShow = null;
				this.articleAttachments = [];
				if (data) {
					this.articles = data?.articles;
					if (this.articles.length === 1) {
						this.setArticleToShow(this.articles[0]);
					}
				}
			});
	}

	setRouteLabels() {
		let routeArray = this.route.url
			.split('?')[0]
			.split('/')
			.filter((label) => isNaN(Number(label)))
			.filter((label) => label !== '');

		this.routeLabels = [...routeArray];
	}

	getLabelForHelpSystem() {
		this.searchHelpText = '';
		let searchAllLabelText = '';
		searchAllLabelText = this.routeLabels.join(' ');
		this.getContextualHelp(searchAllLabelText.toLowerCase());
	}

	getContextualHelp(label: string) {
		this.htmlString = '';
		this.loadingArticle = true;
		this.helpService
			.getArticleBasedOnLabels(label)
			.subscribe((data: any) => {
				this.loadingArticle = false;
				this.getArticleBasedOnLabel(data?.results);
				this.articles = [];
				this.articleAttachments = [];
			});
	}

	searchHelpArticles(label: string) {
		this.htmlString = '';
		this.loadingArticle = true;
		this.helpService
			.helpSystem(label.toLowerCase())
			.subscribe((data: any) => {
				this.loadingArticle = false;
				this.articles = data?.results;
				this.articleToShow = null;
				this.articleAttachments = [];
			});
	}

	getArticleAttachments(articleId, parentElement) {
		this.helpService
			.getArticleAttachments(articleId)
			.subscribe((data: any) => {
				this.articleAttachments = data?.article_attachments.filter(
					(attachment) => !attachment.content_type.includes('image/')
				);
			});
	}

	getArticleBasedOnLabel(searchedArticles) {
		this.articleToShow = searchedArticles.filter((article) =>
			this.checkLables(this.routeLabels, article?.label_names)
		)?.[0];
		this.getArticleHtmlDocument();
	}

	checkLables(routeLabels, articleLabels) {
		if (routeLabels.length === articleLabels.length) {
			return articleLabels.every((element) => {
				if (routeLabels.includes(element.toLowerCase())) {
					return true;
				}
				return false;
			});
		}
		return false;
	}

	closeHelpSystem() {
		this.searchHelpText = '';
		this.isSearchEventTriggered = false;
		this.broadcastService.hideHelpSystem();
		this.articles = [];
		this.articleToShow = null;
		this.articleAttachments = [];
	}

	searchHelp(event) {
		this.isSearchEventTriggered = true;
		this.articles = [];
		this.articleToShow = null;
		if (event) {
			this.searchHelpArticles(event);
		} else {
			this.getHelpArticlesBasedOnLabel();
		}
	}

	setArticleToShow(article) {
		this.articleToShow = article;
		setTimeout(() => {
			this.getArticleHtmlDocument();
		}, 1);
	}

	backToArticlesList() {
		this.articleToShow = null;
	}

	getArticleHtmlDocument() {
		let parser = new DOMParser();
		this.articleToShowHtml = parser.parseFromString(
			this.articleToShow?.body,
			'text/html'
		);

		this.htmlString = this.articleToShow?.body;
	}
}
