import { createAction, props } from '@ngrx/store';
import { TestInterface } from 'src/app/core/models/take-test.interface';
import { LPSideBarInterface } from '../lp-side-bar/lp-side-bar.component';
import {
	CourseDeatilsInterface,
	LPCourseInterface,
	MyCourseListInterface,
} from '../_models/course-details.interface';
import { CourseQuizSummaryInterface } from '../_models/course-quiz.interface';
import { CurrentTestDetailsInterface } from '../_services/take-test.service';

export enum MyCourseActionTypesEnum {
	/**
	 * Course Related
	 */

	GET_COURSES = '[My Course] Get Courses',
	// GET_RC_COURSE = '[Resource Center] Get Courses',
	GET_COURSES_SUCCESS = '[My Course] Get Courses Success',
	GET_COURSES_FAILURE = '[My Course] Get Courses Failure',
	GET_MANDATORY_COURSES = '[My Course] Get Mandatory Courses ',
	SAVE_MANDATORY_COURSES = '[My Course] Save Mandatory Courses ',
	GET_RECOMMENDED_COURSES = '[My Course] Get Recommended Courses ',
	SAVE_RECOMMENDED_COURSES = '[My Course] Save Recommended Courses ',
	GET_COURSE_DEATILS = '[My Course] Get Course Details',
	SELECT_COURSE = '[My Course] Select Course',
	UPDATE_SELECTED_COURSE = '[My Course] Update Selected Course',
	SELECT_LP = '[My Course] Select LP',
	UPDATE_PROGRESS = '[My Course] Update Progress',
	UPDATE_OPTION = '[My Course] Update Option',
	CAPTURE_TEST_ANSWERS = '[My Course] Capture Test Answers',
	INIT_LP_SIDEBAR_MENU = '[My Course] Init LP sidebar menu',
	INIT_COURSE_MENU = '[My Course] Init course menu',
	SAVE_SIDEBAR_MENU = '[My Course] Save Sidebar menu',
	SAVE_COURSE_MENU = '[My Course] Save course menu',
	SET_LP = '[My Course] SET LP',
	ON_ENTITY_COMPLETION = '[My Course] on entity completion',
	UPDATE_SUB_MENU = '[My Course] Update submenu selected item',

	// New actions

	GET_COURSE = '[My Course] get course',
	SAVE_COURSE = '[My Course] save course',
	GET_LP = '[My Course] get lp',
	SAVE_LP = '[My Course] save lp',
	GET_TEST = '[My Course] get test',
	SAVE_TEST = '[My Course] save test',
	GET_TEST_DETAILS = '[My Course] get test details',
	SAVE_TEST_DETAILS = '[My Course] save test details',
	UPDATE_LP = '[My Course] update lp',
	UPDATE_COURSE = '[My Course] update course',
	RESET_NEW_CODE = '[My Course] reset new code',
	CHANGE_CONGRATULATIONS_POPUP_STATE = '[My Course], change congratulations popup state',
	START_COURSE_TIMER = '[My Course] start course timer',
	STOP_COURSE_TIMER = '[My Course] stop course timer',
	UPDATE_COURSE_TIMER = '[My Course] update course timer',
	MAX_ATTEMPT_REACHED = '[My Course] max test attempt reached',
	CALCULATE_LP_PROGRESS = '[My Course] calculate LP progress',

	/**
	 * Test Related
	 */
	INITALISE_TEST = '[My Course - Take Test] initialise',
	SELECT_ANSWER = '[My Course - Take Test] select answer',
	CALCULATE_TOTAL = '[My Course - Take Test] calculate total',
	SUBMIT_TEST = '[My Course - Take Test] submit test',
	RESET_TEST = '[My Course - Take Test] reset test',
	REACHING_MAX_ATTEMPT_FOR_TEST = '[my course - take test] reaching max attempt for a test ',

	/**
	 * Orientaion Mode Related Actions
	 */
	CHECK_USER_ORIENTATION_STATUS = '[my course - orientation] check user orientation status',
	SHOW_USER_ORIENTATION_STATUS = '[my course - orientation] show user orientation status',
	HIDE_USER_ORIENTATION_STATUS = '[my course - orientation] hide user orientation status',
}

export const getCourses = createAction(
	MyCourseActionTypesEnum.GET_COURSES,
	props<{ courseStatus: number }>()
);
export const getCoursesSuccess = createAction(
	MyCourseActionTypesEnum.GET_COURSES_SUCCESS,
	props<{
		courses: [
			Array<MyCourseListInterface>,
			// Array<MyCourseListInterface>,
			Array<MyCourseListInterface>
		];
	}>()
);
export const getCoursesFailure = createAction(
	MyCourseActionTypesEnum.GET_COURSES_FAILURE,
	props<{ error: string }>()
);
export const getMandatoryCourses = createAction(
	MyCourseActionTypesEnum.GET_MANDATORY_COURSES,
	props<{ courseStatus: number }>()
);
export const saveMandatoryCourses = createAction(
	MyCourseActionTypesEnum.SAVE_MANDATORY_COURSES,
	props<{ courses: Array<MyCourseListInterface>; totalTime: number }>()
);
export const getRecommendedCourses = createAction(
	MyCourseActionTypesEnum.GET_RECOMMENDED_COURSES
	// props<{ courseStatus: number }>()
);
export const saveRecommendedCourses = createAction(
	MyCourseActionTypesEnum.SAVE_RECOMMENDED_COURSES,
	props<{ courses: Array<MyCourseListInterface> }>()
);

export const getCourseDetails = createAction(
	MyCourseActionTypesEnum.GET_COURSE_DEATILS,
	props<{
		courseId: number;
		learningPathway: boolean;
		selfAssigned: boolean;
		userAssignmentId: number;
	}>()
);
export const getCourse = createAction(
	MyCourseActionTypesEnum.GET_COURSE,
	props<{
		courseId: number;
		selfAssigned: boolean;
		userAssignmentId: number;
		parent?: boolean;
	}>()
);
export const saveCourse = createAction(
	MyCourseActionTypesEnum.SAVE_COURSE,
	props<{ course: CourseDeatilsInterface; selfAssigned?: boolean }>()
);
export const getLP = createAction(
	MyCourseActionTypesEnum.GET_LP,
	props<{
		courseId: number;
		selfAssigned: boolean;
		assignmentId: number;
	}>()
);
export const saveLP = createAction(
	MyCourseActionTypesEnum.SAVE_LP,
	props<{ lp: LPCourseInterface; selfAssigned?: boolean }>()
);
export const getTest = createAction(
	MyCourseActionTypesEnum.GET_TEST,
	props<{
		testId: number;
		courseType: 'lp' | 'course';
		courseId: number;
		maxAttempt: number;
		passingScore: number;
		testType: 'pretest' | 'posttest' | 'microtest';
		userAssignmentId: number;
		assignmentId: number;
	}>()
);
export const saveTest = createAction(
	MyCourseActionTypesEnum.SAVE_TEST,
	props<{
		test: TestInterface;
		testObject: CurrentTestDetailsInterface;
	}>()
);
export const getTestDetails = createAction(
	MyCourseActionTypesEnum.GET_TEST_DETAILS,
	props<{
		lp?: { id: number; aid: number };
		course?: { uaid: number };
		testObject: CurrentTestDetailsInterface;
	}>()
);
export const saveTestDetails = createAction(
	MyCourseActionTypesEnum.SAVE_TEST_DETAILS,
	props<{
		details: CourseQuizSummaryInterface;
		testObject: CurrentTestDetailsInterface;
	}>()
);
export const changeCongratulationsPopupState = createAction(
	MyCourseActionTypesEnum.CHANGE_CONGRATULATIONS_POPUP_STATE,
	props<{ opened: boolean; courseName: string; isLP: boolean }>()
);
// export const getRcCourseDetails = createAction(
// 	MyCourseActionTypesEnum.GET_RC_COURSE,
// 	props<{ courseId: number; learningPathway: boolean }>()
// );
export const updateProgress = createAction(
	MyCourseActionTypesEnum.UPDATE_PROGRESS,
	props<{ cid: number; progress: number }>()
);
export const updateoption = createAction(
	MyCourseActionTypesEnum.UPDATE_OPTION,
	props<{ option: number }>()
);

export const selectLP = createAction(
	MyCourseActionTypesEnum.SELECT_LP,
	props<{ lp: LPCourseInterface; fromRc: boolean; id?: number }>()
);
export const selectCourse = createAction(
	MyCourseActionTypesEnum.SELECT_COURSE,
	props<{ course: CourseDeatilsInterface; isLiveEvent?: boolean }>()
);
export const updateSelectedCourse = createAction(
	MyCourseActionTypesEnum.UPDATE_SELECTED_COURSE,
	props<{
		item: LPSideBarInterface;
		completePrevious?: boolean;
	}>()
);
// export const updateSelectedCourse = createAction(
// 	MyCourseActionTypesEnum.UPDATE_SELECTED_COURSE,
// 	props<{
// 		courseId: number;
// 		selfAssigned: boolean;
// 		userAssignmentId: number;
// 	}>()
// );

export const initialise = createAction(
	MyCourseActionTypesEnum.INITALISE_TEST,
	props<{ test: TestInterface; testType: 'course' | 'lp' }>()
);
export const selectAnswer = createAction(
	MyCourseActionTypesEnum.SELECT_ANSWER,
	props<{ qid: number; selectedOptions: Array<number> | string }>()
);

export const calculateTotal = createAction(
	MyCourseActionTypesEnum.CALCULATE_TOTAL
);

export const submitTest = createAction(
	MyCourseActionTypesEnum.SUBMIT_TEST,
	props<{
		result: {
			testScore: number;
			retakeTest: boolean;
			testStatus: 'Failed' | 'Passed';
		};
	}>()
);

export const captureTestAnswer = createAction(
	MyCourseActionTypesEnum.CAPTURE_TEST_ANSWERS,
	props<{ userTestHistoryId: number }>()
);

export const initLPSidebarMenu = createAction(
	MyCourseActionTypesEnum.INIT_LP_SIDEBAR_MENU,
	props<{ lp: LPCourseInterface }>()
);
export const saveLPSidebarMenu = createAction(
	MyCourseActionTypesEnum.SAVE_SIDEBAR_MENU,
	props<{ menu: LPSideBarInterface[] }>()
);

export const resetTest = createAction(MyCourseActionTypesEnum.RESET_TEST);

export const setLP = createAction(
	MyCourseActionTypesEnum.SET_LP,
	props<{ value: boolean }>()
);

export const initCourseMenu = createAction(
	MyCourseActionTypesEnum.INIT_COURSE_MENU,
	props<{ course: CourseDeatilsInterface; parent: boolean }>()
);
export const saveCourseMenu = createAction(
	MyCourseActionTypesEnum.SAVE_COURSE_MENU,
	props<{ menu: LPSideBarInterface[] }>()
);
export const onEntityCompletion = createAction(
	MyCourseActionTypesEnum.ON_ENTITY_COMPLETION
	// props<{}>()
);
export const updateSubMenu = createAction(
	MyCourseActionTypesEnum.UPDATE_SUB_MENU,
	props<{ item: LPSideBarInterface; parent: boolean }>()
);

export const resetNewCode = createAction(
	MyCourseActionTypesEnum.RESET_NEW_CODE
);

export const startCourseTimer = createAction(
	MyCourseActionTypesEnum.START_COURSE_TIMER,
	props<{ seconds: number }>()
);
export const stopCourseTimer = createAction(
	MyCourseActionTypesEnum.STOP_COURSE_TIMER
);

export const updateCourseTimer = createAction(
	MyCourseActionTypesEnum.UPDATE_COURSE_TIMER,
	props<{ from?: number }>()
);
export const maxAttemptReached = createAction(
	MyCourseActionTypesEnum.MAX_ATTEMPT_REACHED
);

export const checkUserOrientationMode = createAction(
	MyCourseActionTypesEnum.CHECK_USER_ORIENTATION_STATUS
);
export const showUserOrientationMode = createAction(
	MyCourseActionTypesEnum.SHOW_USER_ORIENTATION_STATUS
);
export const hideUserOrientationMode = createAction(
	MyCourseActionTypesEnum.HIDE_USER_ORIENTATION_STATUS
);
export const calculateLpProgress = createAction(
	MyCourseActionTypesEnum.CALCULATE_LP_PROGRESS
);

export const onReachingMaxAttemptForTest = createAction(
	MyCourseActionTypesEnum.REACHING_MAX_ATTEMPT_FOR_TEST,
	props<{ testId: number; courseType: 'lp' | 'course' }>()
);
