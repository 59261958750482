<mat-form-field
	appearance="outline"
	class="auth"
	[ngClass]="{
		error:
			fgName.get(controlName).invalid && fgName.get(controlName).touched
	}"
	[formGroup]="fgName"
>
	<mat-label>{{ label }}</mat-label>
	<input
		matInput
		(keydown.enter)="$event.preventDefault()"
		(keyup)="fgName.get(controlName).markAsTouched(); setErrorMessage()"
		[formControlName]="controlName"
		[type]="hide ? 'password' : 'text'"
	/>
	<div matSuffix class="d-flex align-items-center">
		<button mat-icon-button type="button" (click)="$event.preventDefault()">
			<mat-icon
				#myButton
				(mouseover)="showMessage(requirements)"
				(mouseout)="closeMessage()"
				>info</mat-icon
			>
		</button>

		<button
			type="button"
			mat-icon-button
			matSuffix
			(click)="hide = !hide; $event.preventDefault()"
			[attr.aria-label]="'Hide password'"
			[attr.aria-pressed]="hide"
		>
			<span class="material-icons-outlined">{{
				hide ? "visibility_off" : "visibility"
			}}</span>
		</button>
	</div>
	<mat-error
		*ngIf="fgName.get(controlName).invalid"
		[innerHtml]="errorMessage"
	></mat-error>
</mat-form-field>

<ng-template #requirements>
	<div [innerHtml]="passwordTooltipText"></div>
</ng-template>