import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
	name: 'slrDate',
})
export class SLRDatePipe implements PipeTransform {
	transform(value: any, ...args: any[]) {
		if (!value) return;
		if (typeof value === 'number' || !isNaN(+value)) return value;
		if (!moment(value, true).isValid()) return value;
		return moment(value).format('YYYY-MM-DD');
	}
}
@Pipe({
	name: 'dateConverter',
})
export class DateConverterPipe implements PipeTransform {
	transform(value: any, ...args: any[]) {
		if (!value) return '';
		return moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
	}
}
