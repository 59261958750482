import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'skillStatusTrasnlate',
})
export class SkillStatusTrasnlatePipe implements PipeTransform {
	private readonly APPROVERD = 'Approved';
	private readonly PENDING_APPROVAL = 'Pending Approval';
	private readonly PENDING_COURSE_COMPLETION = 'Pending Course Completion';
	private readonly PENDING_RENEWAL = 'Pending Renewal';
	constructor(private translateService: TranslateService) {}
	transform(value: any, ...args: any[]) {
		if (value.toLowerCase() == this.APPROVERD.toLowerCase()) {
			return this.translateService.instant('APPROVED');
		}
		if (value.toLowerCase() == this.PENDING_APPROVAL.toLowerCase()) {
			return this.translateService.instant('PENDING_APPROVAL');
		}
		if (
			value.toLowerCase() == this.PENDING_COURSE_COMPLETION.toLowerCase()
		) {
			return this.translateService.instant('PENDING_COURSE_COMPLETION');
		}
		if (value.toLowerCase() == this.PENDING_RENEWAL.toLowerCase()) {
			return this.translateService.instant('PENDING_RENEWAL');
		}
	}
}
