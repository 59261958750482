import { HttpEventType } from '@angular/common/http';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
	Subject,
	filter,
	finalize,
	iif,
	map,
	mergeMap,
	of,
	takeUntil,
	tap,
} from 'rxjs';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { VimeoService } from 'src/app/core/services/vimeo.service';
import { PreviewFileDetails } from 'src/app/shared/models/create-resource';
import { ResourceCenterService } from '../../../_services/resource-center.service';
import { ReadZipFileService } from 'src/app/core/services/read-zip-file.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-uploaded-file-entry',
	templateUrl: './uploaded-file-entry.component.html',
	styleUrls: ['./uploaded-file-entry.component.scss'],
})
export class UploadedFileEntryComponent implements OnInit, OnDestroy {
	@ViewChild('downloadFile') downloadDocFile: ElementRef;
	public fileForm: FormGroup;
	public openModal = false;
	public downloadFile;
	@Input() isEdit: boolean = false;
	@Input() formArrayName: string;
	@Input() arrayIndex: number;
	@Input() hideRemove: boolean = false;
	@Output() deleteEntry: EventEmitter<boolean> = new EventEmitter();
	private readonly unsubscriber$ = new Subject();
	public filePath: SafeResourceUrl;
	public fileDetails: PreviewFileDetails = {} as PreviewFileDetails;
	public isSurgeUser: boolean = false;

	constructor(
		private readonly fileUploadService: FileUploadService,
		private readonly formGroupDirective: FormGroupDirective,
		private readonly resourceCenterService: ResourceCenterService,
		private readonly sanitizer: DomSanitizer,
		private readonly localData: LocalDataService,
		private readonly notificationService: NotificationService,
		private readonly vimeoService: VimeoService,
		private readonly zipFileService: ReadZipFileService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly translateService: TranslateService
	) {}

	public onDeleteEntry(): void {
		this.deleteEntry.emit(true);
	}

	private get courseId() {
		return this.activatedRoute.snapshot.params['id'];
	}

	public onPreviewEntry(): void {
		if (
			GenericHelper.previewableTypes.some((type) =>
				type.includes(this.fileDetails.fileType)
			) &&
			this.fileDetails.fileType !== ''
		) {
			this.openModal = true;
			this.localData.modalHeader = 'File Preview';
			return;
		}
		if (
			GenericHelper.downloadableTypes.some((type) =>
				type.includes(this.fileDetails.fileType)
			)
		) {
			this.downloadDocFile.nativeElement.click();
			return;
		}
		this.notificationService.error('File Type cannot be previewed');
	}

	ngOnInit(): void {
		this.fileForm = this.returnFormGroup();
		this.isSurgeUser = this.localData
			.getUserClasses()[0]
			.toLowerCase()
			.includes('surge');
		if (this.isEdit) {
			this.preparePreview();
		}
		if (this.fileForm.value.type == 'scorm' && this.fileForm.value.file) {
			this.checkScormFileForManifestAndUpload(this.fileForm.value.file);
			return;
		}
		if (this.fileForm.value.type == 'vimeo' && this.fileForm.value.file) {
			this.prepareVimeoUpload(this.fileForm.value.file);
			return;
		}

		if (this.fileForm.value.type == 'scorm' && this.fileForm.value.link) {
			this.uploadScormLink(this.fileForm.value.link);
			return;
		}

		if (this.fileForm.value.file) {
			this.uploadFile(
				this.fileForm.value.file,
				this.fileForm.value.fileType
			);
		}
	}

	private preparePreview(): void {
		const fileDetail = this.fileForm.value;
		if (fileDetail.type === 'internal') {
			this.getPresignedFileUrl(
				fileDetail.filePath,
				fileDetail.name,
				fileDetail.fileType
			);
		}
	}

	private prepareVimeoUpload(file: File): void {
		this.fileForm.patchValue({
			uploadProgress: 20,
			uploaded: false,
		});
		this.vimeoService.prepareTusUploadForVimeo(file).subscribe({
			next: (res) => {
				this.uploadVimeoContent();
			},
		});
	}

	private uploadVimeoContent(): void {
		this.resourceCenterService.scormCourseUploading.next(true);
		this.fileForm.patchValue({
			uploadProgress: 50,
			uploaded: false,
		});
		this.vimeoService.uploadVideo().subscribe({
			next: (res) => {
				if (res.type === HttpEventType.Response) {
					this.fileForm.patchValue({
						uploadProgress: 90,
						uploaded: false,
					});
					setTimeout(() => this.verifyVimeoUpload(), 500);
				}
			},
		});
	}

	private verifyVimeoUpload(): void {
		this.vimeoService.verifyTusUpload().subscribe({
			next: (res) => {
				if (res) {
					const currentUploadPros =
						this.vimeoService.currentUploadProps;
					this.fileForm.patchValue({
						filePath: currentUploadPros.embedLink,
					});
					this.fileDetails = {
						fileUrl: currentUploadPros.embedLink,
						fileName: currentUploadPros.file.name,
						fileType: currentUploadPros.file.type,
						filePathInSystem: currentUploadPros.file,
					};
					this.fileForm.patchValue({
						uploadProgress: 100,
						uploaded: true,
					});
					this.resourceCenterService.scormCourseUploading.next(false);
					this.vimeoService.resetCurentVimeoUploadProps();
				}

				if (!res) {
					this.uploadVimeoContent();
				}
			},
		});
	}

	private checkScormFileForManifestAndUpload(file): void {
		this.resourceCenterService.scormCourseUploading.next(true);
		this.zipFileService
			.checkIfFileExists(file, 'imsmanifest.xml')
			.pipe(
				mergeMap((fileExists) =>
					iif(
						() => fileExists,

						this.checkScormSize(fileExists, file),
						this.checkFileSize(fileExists, file)
					)
				),
				tap(
					({ type, res }) =>
						res.type == HttpEventType.UploadProgress &&
						this.handleUploadProgress(res)
				),
				filter(({ type, res }) => res.type === HttpEventType.Response)
			)
			.subscribe({
				next: ({ type, res }) => {
					switch (type) {
						case 'scorm': {
							const key = res.body['result'];
							this.checkScormUploadStatus(key, file);
							break;
						}
						case 'non-scorm':
							this.checkNonScormUploadStatus(
								`${res.body}/${file.name}`
							);
							break;
					}
				},
			});
	}

	private checkFileSize(fileExists, file) {
		if (fileExists) {
			return of(null);
		}
		if (this.isSurgeUser) {
			if (
				file.size > this.localData.resourcePdfContentSizeForSurgeAdmin
			) {
				this.notificationService.error('FILE_SIZE_ERROR');
				return of(null);
			}
		} else if (file.size > this.localData.resourcePdfContentSize) {
			this.notificationService.error('FILE_SIZE_ERROR');
			return of(null);
		}

		return this.fileUploadService.uploadToS3(file).pipe(
			map((res) => {
				return {
					type: 'non-scorm',
					res,
				};
			})
		);
	}

	checkScormSize(fileExists, file) {
		if (this.isSurgeUser) {
			if (file.size > this.localData.scormCourseSizeForSurgeAdmin) {
				this.notificationService.error('FILE_SIZE_ERROR');
				return of(null);
			}
		} else if (file.size > this.localData.scormCourseSize) {
			this.notificationService.error('FILE_SIZE_ERROR');
			return of(null);
		}

		return this.uploadScormCourse(file).pipe(
			map((res) => {
				return { type: 'scorm', res };
			})
		);
	}

	private handleUploadProgress(res) {
		const progress = Math.round(100 * (res.loaded / res.total));
		this.fileForm.patchValue({
			uploadProgress: progress,
			uploaded: progress == 100,
		});
	}

	private uploadScormCourse(file: File) {
		return this.resourceCenterService.uploadScormCourse(
			file,
			this.isEdit ? this.courseId : undefined
		);
	}

	private uploadScormLink(link: string): void {
		this.resourceCenterService
			.uploadScormLink(link, this.fileForm.value.duration)
			.subscribe({
				next: (res) => {
					console.log(res);
				},
			});
	}

	private checkScormUploadStatus(key: string, file: File): void {
		this.resourceCenterService.checkScormUploadStatus(key).subscribe({
			next: (res) => {
				if (res.status == 'ERROR') {
					this.resourceCenterService.scormUploadError.next(true);
					this.notificationService.error(
						'Content Upload Error - ' + res.message
					);
				}
				if (res.status == 'COMPLETE') {
					if (res.importResult.parserWarnings.length != 0) {
						const warnings = res.importResult
							.parserWarnings as string[];
						const hasIncorrectlyPackagedWarning = warnings.some(
							(x) =>
								x.includes(
									'The zip file was incorrectly packaged'
								)
						);
						if (hasIncorrectlyPackagedWarning) {
							this.notificationService.error(
								'Upload Error - ' +
									res.importResult.parserWarnings[0]
							);
							this.resourceCenterService.scormUploadError.next(
								true
							);
							return;
						}
					}
					this.resourceCenterService.scormCourseUploading.next(false);
					this.isEdit &&
						this.resourceCenterService
							.markExistingRegistrations(this.courseId)
							.subscribe();
					this.notificationService.success(
						`${this.translateService.instant(
							'COURSE'
						)} ${this.translateService.instant(
							'UPLOAD'
						)} ${this.translateService.instant('SUCCESS')}`
					);
					this.resourceCenterService.scormUploadError.next(false);
					this.fileDetails = {
						fileUrl: 'rustici',
						fileName: file.name,
						fileType: file.type,
						filePathInSystem: file,
					};
				}
			},
		});
	}

	private checkNonScormUploadStatus(key: string) {
		this.resourceCenterService.processNonScormZIP(key).subscribe({
			next: (res) => {
				this.resourceCenterService.scormCourseUploading.next(false);
				const filePath = res['filePath'];
				const nonScormUploadStatus = res['nonScormUploadStatus'];
				if (nonScormUploadStatus == 'SUCCESS') {
					this.fileForm.patchValue({
						filePath: filePath,
						type: 'non-scorm',
					});
				}
				if (nonScormUploadStatus == 'NOT_VALID') {
					this.fileForm.patchValue({
						filePath: filePath,
						type: 'invalid_non_scorm',
					});
				}
				if (
					nonScormUploadStatus == 'FAILED' ||
					nonScormUploadStatus == 'INVALID_ZIP'
				) {
					this.resourceCenterService.scormUploadError.next(true);
				}
			},
		});
	}
	public get showDuration(): boolean {
		return (
			(
				(
					this.formGroupDirective.control.get(
						this.formArrayName
					) as FormArray
				).at(this.arrayIndex) as FormGroup
			).get('duration') !== null
		);
	}
	public returnFormGroup(): FormGroup {
		const formArray = <FormArray>(
			this.formGroupDirective.control.get(this.formArrayName)
		);
		return <FormGroup>formArray.at(this.arrayIndex);
	}
	private uploadFile(file: File, contentType: string): void {
		this.resourceCenterService.scormCourseUploading.next(true);
		this.fileUploadService
			.uploadToS3(file)
			.pipe(
				finalize(() => {
					this.fileForm.patchValue({
						uploaded: true,
						uploadProgress: 100,
					});
				})
			)
			.subscribe({
				next: (res) => {
					if (res['type'] == HttpEventType.UploadProgress) {
						const uploadProgress = Math.floor(
							100 * (res['loaded'] / res['total'])
						);
						this.fileForm.patchValue({
							uploadProgress:
								uploadProgress > 90
									? uploadProgress - 1
									: uploadProgress,
						});
					}
					if (res['type'] === HttpEventType.Response) {
						this.resourceCenterService.scormCourseUploading.next(
							false
						);
						this.getPresignedFileUrl(
							res.body,
							file.name,
							file.type
						);
					}
				},
			});
	}
	private getPresignedFileUrl(location, fileName, fileType) {
		this.fileForm.patchValue({ filePath: location });
		this.resourceCenterService
			.getPresignedUrl(`${location}/${fileName}`)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((res) => {
				this.fileDetails = {
					fileUrl: this.bypassUrlSecurity(res.s3PresingedURL),
					fileName,
					fileType,
				};
			});
	}
	private bypassUrlSecurity(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
	ngOnDestroy(): void {
		this.unsubscriber$.next(true);
		if (
			this.formArrayName == 'content' &&
			this.resourceCenterService.scormUploadError.value
		)
			this.resourceCenterService.scormUploadError.next(false);
	}
	onCloseModal(e: boolean) {
		this.openModal = false;
	}
}
