<mat-form-field
	class="auth"
	[ngClass]="{ error: control.invalid && control.touched }"
>
	<input
		matInput
		(keydown.enter)="$event.preventDefault()"
		(keyup)="control.markAsTouched(); setErrorMessage()"
		[formControl]="control"
		[placeholder]="placeholder"
		[type]="hide ? 'password' : 'text'"
	/>
	<div matSuffix class="d-flex align-items-center">
		<app-password-pattern-message></app-password-pattern-message>
		<button
			mat-icon-button
			(click)="hide = !hide"
			[attr.aria-label]="'Hide password'"
			[attr.aria-pressed]="hide"
		>
			<span class="material-icons-outlined">{{
				hide ? "visibility_off" : "visibility"
			}}</span>
		</button>
	</div>
	<mat-error
		class="br-red error"
		*ngIf="control.invalid"
		[innerHtml]="errorMessage"
	></mat-error>
</mat-form-field>