<div *ngIf="activeStep === 2">
	<section class="mb-3 rc-uploads" *ngIf="isLearningpathway">
		<div class="d-flex">
			<div class="form-group col-6">
				<label for="select-resources ">
					{{ "SELECT_COURSES" | translate }}
				</label>
				<ng-select
					class="col-12 mb-3 mt-2"
					[items]="resourcesData"
					[multiple]="true"
					bindLabel="name"
					[selectableGroup]="true"
					[closeOnSelect]="false"
					[clearable]="false"
					[virtualScroll]="true"
					[(ngModel)]="selectedCourses"
					(change)="changeResources($event, 'course')"
				>
					<ng-template
						ng-option-tmp
						let-item="item"
						let-item$="item$"
						let-index="index"
					>
						<span
							class="ng-option-label ms-2"
							[matTooltip]="item.name"
						>
							<input
								type="checkbox"
								id="item-{{ index }}-res"
								class="slr-checkbox form-check-input"
								[ngModel]="item$.selected"
							/>
							<span class="ng-option-label ms-2">
								{{ item.name }}
							</span>
						</span>
					</ng-template>
					<ng-template
						ng-multi-label-tmp
						let-items="items"
						let-clear="clear"
					>
						<div
							class="ng-value"
							*ngFor="let item of items | slice : 0 : 2"
						>
							<span class="ng-value-label">
								{{ $any(item).name }}</span
							>
							<span
								class="ng-value-icon right"
								(click)="clear(item)"
								(keyup)="clear(item)"
								aria-hidden="true"
								>×</span
							>
						</div>
						<div class="ng-value" *ngIf="items.length > 2">
							<span class="ng-value-label"
								>{{ items.length - 2 }} more...</span
							>
						</div>
					</ng-template>
				</ng-select>
			</div>
			<div class="col-1">&nbsp;</div>
			<div class="form-group col-5">
				<label for="select-resources">
					{{ "SELECT_LIVE_EVENTS" | translate }}
				</label>
				<ng-select
					class="col-12 mb-3 mt-2"
					id="select-resources"
					[items]="availableLiveEvents"
					[multiple]="true"
					bindLabel="liveEventName"
					[selectableGroup]="true"
					[closeOnSelect]="false"
					[clearable]="false"
					[virtualScroll]="true"
					[(ngModel)]="selectedLiveEvents"
					(change)="changeResources($event, 'event')"
				>
					<ng-template
						ng-option-tmp
						let-item="item"
						let-item$="item$"
						let-index="index"
					>
						<span
							class="ng-option-label ms-2"
							[matTooltip]="item.liveEventName"
						>
							<input
								type="checkbox"
								id="item-{{ index }}-res"
								class="slr-checkbox form-check-input"
								[ngModel]="item$.selected"
							/>
							<span class="ng-option-label ms-2">
								{{ item.liveEventName }}
							</span>
						</span>
					</ng-template>
					<ng-template
						ng-multi-label-tmp
						let-items="items"
						let-clear="clear"
					>
						<div
							class="ng-value"
							*ngFor="let item of items | slice : 0 : 2"
						>
							<span class="ng-value-label">
								{{ $any(item).liveEventName }}</span
							>
							<span
								class="ng-value-icon right"
								(click)="clear(item)"
								(keyup)="clear(item)"
								aria-hidden="true"
								>×</span
							>
						</div>
						<div class="ng-value" *ngIf="items.length > 2">
							<span class="ng-value-label"
								>{{ items.length - 2 }} more...</span
							>
						</div>
					</ng-template>
				</ng-select>
			</div>
		</div>
		<div class="d-flex">
			<div class="form-group col-6">
				<label for="select-docs">
					{{ "SELECT_DOCS" | translate }}
				</label>
				<ng-select
					class="col-12 mb-3 mt-2"
					id="select-docs"
					[items]="docsData"
					[multiple]="true"
					bindLabel="name"
					[clearable]="false"
					[selectableGroup]="true"
					[closeOnSelect]="false"
					[virtualScroll]="true"
					[(ngModel)]="selectedCourses"
					(change)="changeResources($event, 'docs')"
				>
					<ng-template
						ng-option-tmp
						let-item="item"
						let-item$="item$"
						let-index="index"
					>
						<span
							class="ng-option-label ms-2"
							[matTooltip]="item.name"
						>
							<input
								type="checkbox"
								id="item-{{ index }}-res"
								class="slr-checkbox form-check-input"
								[ngModel]="item$.selected"
							/>
							<span class="ng-option-label ms-2">
								{{ item.name }}
							</span>
						</span>
					</ng-template>
					<ng-template
						ng-multi-label-tmp
						let-items="items"
						let-clear="clear"
					>
						<div
							class="ng-value"
							*ngFor="let item of items | slice : 0 : 2"
						>
							<span class="ng-value-label">
								{{ $any(item).name }}</span
							>
							<span
								class="ng-value-icon right"
								(click)="clear(item)"
								(keyup)="clear(item)"
								aria-hidden="true"
								>×</span
							>
						</div>
						<div class="ng-value" *ngIf="items.length > 2">
							<span class="ng-value-label"
								>{{ items.length - 2 }} more...</span
							>
						</div>
					</ng-template>
				</ng-select>
			</div>
			<div class="col-1">&nbsp;</div>
			<div class="form-group col-5">
				<label for="select-resources ">
					{{ "SELECT_SURVEY" | translate }}
				</label>
				<ng-select
					class="col-12 mb-3 mt-2"
					[items]="surveys"
					[multiple]="true"
					bindLabel="name"
					[clearable]="false"
					[selectableGroup]="true"
					[closeOnSelect]="false"
					[virtualScroll]="true"
					[(ngModel)]="selectedCourses"
					(change)="changeResources($event, 'survey')"
				>
					<ng-template
						ng-option-tmp
						let-item="item"
						let-item$="item$"
						let-index="index"
					>
						<span
							class="ng-option-label ms-2"
							[matTooltip]="item.name"
						>
							<input
								type="checkbox"
								id="item-{{ index }}-res"
								class="slr-checkbox form-check-input"
								[ngModel]="item$.selected"
							/>
							<span class="ng-option-label ms-2">
								{{ item.name }}
							</span>
						</span>
					</ng-template>
					<ng-template
						ng-multi-label-tmp
						let-items="items"
						let-clear="clear"
					>
						<div
							class="ng-value"
							*ngFor="let item of items | slice : 0 : 2"
						>
							<span class="ng-value-label">
								{{ $any(item).name }}</span
							>
							<span
								class="ng-value-icon right"
								(click)="clear(item)"
								(keyup)="clear(item)"
								aria-hidden="true"
								>×</span
							>
						</div>
						<div class="ng-value" *ngIf="items.length > 2">
							<span class="ng-value-label"
								>{{ items.length - 2 }} more...</span
							>
						</div>
					</ng-template>
				</ng-select>
			</div>
		</div>
		<div *ngIf="selectedResources.length">
			<div
				class="selected-courses-header p-2 ps-4 pe-4 d-flex justify-content-between"
			>
				<span>
					{{ "SELECTED_COURSES_EVENTS" | translate }}
				</span>
				<span>{{ lpDuration | durationFormat }}</span>
			</div>
			<div
				class="resource-list-container ps-4 pe-4"
				cdkDropList
				(cdkDropListDropped)="dropCourse($event)"
			>
				<div>
					<div
						class="p-2 ps-0 d-flex align-items-center cursor-pointer"
						*ngFor="
							let resource of selectedResources;
							last as last;
							index as i
						"
						[class.border-bottom]="!last"
						cdkDrag
					>
						<span class="material-icons-outlined drag-icon">
							menu
						</span>
						<span class="ps-4">
							{{
								resource?.id
									? resource?.name
									: resource?.liveEventName
							}}
						</span>
						<span
							class="material-icons-outlined color-blue-1 ms-2"
							*ngIf="resource?.liveEventId"
						>
							live_tv
						</span>
						<span
							class="material-icons-outlined color-red-0 ms-auto"
							(click)="
								removeResource(i, resource?.id ? true : false)
							"
							(keyup)="
								removeResource(i, resource?.id ? true : false)
							"
							>close</span
						>
					</div>
				</div>
			</div>
		</div>
	</section>
	<form [formGroup]="resourcesForm" *ngIf="!isLearningpathway">
		<section class="mb-3">
			<h6 class="mb-4">Upload Content/Course</h6>
			<div
				*ngIf="!getFormArray('content')?.value?.length"
				class="d-inline-flex gap-4 color-blue-2 cursor-pointer"
				(click)="openModal(0)"
				(keyup)="openModal(0)"
			>
				<span class="material-icons-outlined">file_upload</span>
				<p>{{ "ADD_CONTENT" | translate }}</p>
			</div>
			<div class="uploaded-files">
				<ng-container
					*ngFor="
						let content of getFormArray('content').controls;
						let i = index
					"
				>
					<app-uploaded-file-entry
						[isEdit]="isEdit"
						[arrayIndex]="i"
						[formArrayName]="'content'"
						(deleteEntry)="deleteFile('content', i)"
					></app-uploaded-file-entry>
					<div *ngIf="isSurgeUser">
						<mat-checkbox [formControlName]="'newWindow'">{{
							"OPEN_IN_EXTERNAL_TAB_MESSAGE" | translate
						}}</mat-checkbox>
					</div>
					<div *ngIf="isSurgeUser && isScormContent">
						<mat-checkbox [formControlName]="'rusticiCompletionFlag'">{{
							"RUSTICI_COMPLETION_FLAG" | translate
						}}</mat-checkbox>
					</div>
				</ng-container>
			</div>
		</section>

		<section
			class="mb-3"
			*ngIf="resourcesForm.value.content[0]"
			appIsDocument
			[fileType]="resourcesForm.value.content[0]?.fileType"
		>
			<h6 class="mb-4">Upload Source Document</h6>
			<div
				class="d-inline-flex gap-4 color-blue-2 cursor-pointer"
				(click)="openModal(1)"
				(keyup)="openModal(1)"
			>
				<span class="material-icons-outlined">file_upload</span>
				<p>{{ "ADD_CONTENT" | translate }}</p>
			</div>
			<div class="uploaded-files">
				<ng-container
					*ngFor="
						let doc of getFormArray('srcDocs').controls;
						let i = index
					"
				>
					<app-uploaded-file-entry
						[isEdit]="isEdit"
						[formArrayName]="'srcDocs'"
						[arrayIndex]="i"
						(deleteEntry)="deleteFile('srcDocs', i)"
					></app-uploaded-file-entry>
				</ng-container>
			</div>
		</section>

		<section
			class="mb-3"
			*ngIf="resourcesForm.value.content[0]"
			appIsDocument
			[fileType]="resourcesForm.value.content[0]?.fileType"
		>
			<h6 class="mb-4">Upload Reference Document</h6>
			<div
				class="d-inline-flex gap-4 color-blue-2 cursor-pointer"
				(click)="openModal(2)"
				(keyup)="openModal(2)"
			>
				<span class="material-icons-outlined">file_upload</span>
				<p>{{ "ADD_CONTENT" | translate }}</p>
			</div>
			<div class="uploaded-files">
				<ng-container
					*ngFor="
						let refDoc of getFormArray('refDocs').controls;
						let i = index
					"
				>
					<app-uploaded-file-entry
						[isEdit]="isEdit"
						[formArrayName]="'refDocs'"
						[arrayIndex]="i"
						(deleteEntry)="deleteFile('refDocs', i)"
					></app-uploaded-file-entry>
				</ng-container>
			</div>
		</section>
	</form>
	<section class="mb-3">
		<form [formGroup]="resourcesForm" class="uploads mt-2">
			<quill-editor
				formControlName="courseDescription"
				placeholder=""
				[styles]="{ height: '220px' }"
			>
			</quill-editor>
		</form>
		<div class="col-12 mt-2">
			<slr-textarea
				[value]="resourcesForm.value.orgSpecificDesc"
				[label]="'Organization specific Description'"
				(onChange)="updateFormValue('orgSpecificDesc', $event)"
			></slr-textarea>
		</div>
	</section>

	<section class="mb-3" *ngIf="!isLearningpathway">
		<div class="micro-learning" *ngIf="showMicroLearning">
			<div class="upload">
				<h6>Upload Micro Learning Module</h6>
				<div
					class="d-inline-flex gap-4 color-blue-2 cursor-pointer mt-3"
					(click)="openModal(3)"
					(keyup)="openModal(3)"
				>
					<span class="material-icons-outlined">file_upload</span>
					<p>{{ "ADD_CONTENT" | translate }}</p>
				</div>
				<form [formGroup]="resourcesForm">
					<app-micro-module></app-micro-module>
				</form>
			</div>
		</div>
	</section>
	<section class="mb-3" *ngIf="isClusterLocation">
		<h6>{{ "Share Course With: " | translate }}</h6>
		<div class="d-flex gap-2 flex-column">
			<ng-container *ngFor="let item of sharedLocationList">
				<mat-checkbox
					[checked]="selectedSharedLocationList.isSelected(item)"
					(change)="selectedSharedLocationList.toggle(item)"
					>{{ item }}</mat-checkbox
				>
			</ng-container>
		</div>
	</section>
	<section class="mb-5">
		<h6>Active Status</h6>
		<mat-radio-group class="d-flex flex-column" [(ngModel)]="activeStatus">
			<ng-container *ngFor="let entry of activeData">
				<mat-radio-button [value]="entry.id" class="mt-2">
					{{ entry.name }}
				</mat-radio-button>
			</ng-container>
		</mat-radio-group>
	</section>

	<div class="form-group mt-4">
		<button
			class="btn submit-btn"
			type="submit"
			[disabled]="isUploading"
			(click)="updateUploads()"
		>
			{{ "SUBMIT_AND_CONTINUE" | translate }}
		</button>
		<button
			class="btn submit-btn ms-2"
			[disabled]="isUploading"
			type="button"
			(click)="updateUploads(false)"
		>
			{{ "SUBMIT" | translate }}
		</button>
		<app-cancel-button
			class="ms-2"
			(click)="routeBack()"
			(keyup)="routeBack()"
		></app-cancel-button>
	</div>
</div>

<app-confirmation-modal
	[openModal]="modalOpen"
	(modalClosed)="onCloseModal($event)"
	[modalSize]="'lg'"
>
	<app-add-content
		[contentType]="selectedType"
		[acceptTypes]="acceptTypes"
		[allowedTypeNames]="'.pdf, .doc, .docx, .mp4, .wmv, .zip'"
		(contentDetails)="updateContents($event)"
	></app-add-content>
</app-confirmation-modal>
