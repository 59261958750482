import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { LiveEventModulePrivilegesEnum } from './core/models/_module_enums/liveevents-module-privileges.enum';
import { ManageUsersModulePrivilegesEnum } from './core/models/_module_enums/manage-users-module.privileges.enum';
import { MyCoursesModulePrivilegesEnum } from './core/models/_module_enums/my-courses-module-privileges.enum';
import { PolicyProfessionalModulePrivilegesEnum } from './core/models/_module_enums/policy-professional-module.privileges.enum';
import { QRMModulePrivilegesEnum } from './core/models/_module_enums/qrm-module.privileges.enum';
import { ReportModulePrivilegesEnum } from './core/models/_module_enums/reports-module.privilege.enum';
import { ResourceCategoriesModulePrivilegesEnum } from './core/models/_module_enums/resource-center-categories-module.privileges.enum';
import { ResourceGroupsModulePrivilegesEnum } from './core/models/_module_enums/resource-center-groups-module.privileges.enum';
import { RosourceCenterModulePrivilegesEnum } from './core/models/_module_enums/resource-center-module-privileges.enum';
import { SettingsModulePrivilegesEnum } from './core/models/_module_enums/settings-module-privilege.enum';
import { SkillsModulePrivilegesEnum } from './core/models/_module_enums/skills-module.privileges.enum';
import { SurveysModulePrivilegesEnum } from './core/models/_module_enums/surveys-module-privileges.enum';
import { CatchScormProgressComponent } from './modules/my-courses/catch-scorm-progress/catch-scorm-progress.component';
import { ReportsModule } from './modules/reports/reports.module';
import { AddNewCodeComponent } from './modules/user-settings/components/manage-codes/add-new-code/add-new-code.component';
import { ManageCodeListComponent } from './modules/user-settings/components/manage-codes/manage-code-list/manage-code-list.component';
import { ProductAccessSettingsComponent } from './modules/user-settings/components/product-access-settings/product-access-settings.component';
import { UserAccessSettingsComponent } from './modules/user-settings/components/user-access-settings/user-access-settings.component';
import { UserSettingsModule } from './modules/user-settings/user-settings.module';
import { AddNewCodeResolver } from './modules/user-settings/_resolvers/add-new-code.resolver';
import { GetProductAccessSettingsResolver } from './modules/user-settings/_resolvers/get-product-access-settings.resolver';
import { GetUserAccessSettingsResolver } from './modules/user-settings/_resolvers/get-user-access-settings.resolver';
import { ManageCodesListResolver } from './modules/user-settings/_resolvers/manage-code-list.resolver';
import { PageNotFoundComponent } from './shared/layouts/page-not-found/page-not-found.component';
import { PrivateLayoutComponent } from './shared/layouts/private-layout/private-layout.component';
import { UnsubscribeComponent } from './shared/layouts/unsubscribe/unsubscribe.component';
import { PrivateLayoutResolver } from './shared/_state/private-layout.resolver';
import { MyProfileModulePrivilegesEnum } from './core/models/_module_enums/my-profile-module.privilege.enum';
import { SystemToolsModulePrivilegesEnum } from './core/models/_module_enums/system-tools-module-privileges.enum';
import { OnboardClientsModulePrivilegesEnum } from './core/models/_module_enums/onboard-clients-module.privileges.enum';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () =>
			import('./modules/authentication/authentication.module').then(
				(m) => m.AuthenticationModule
			),
	},
	{
		path: 'scormprogress',
		component: CatchScormProgressComponent,
	},
	{
		path: 'unsubscribe',
		component: UnsubscribeComponent,
	},
	{
		path: '',
		resolve: { masterData: PrivateLayoutResolver },
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'dashboard',
			},
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./modules/dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					),
			},
			{
				path: 'resources',
				canActivate: [RoleGuard],
				data: {
					privileKey: RosourceCenterModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import(
						'./modules/resource-center/resource-center.module'
					).then((m) => m.ResourceCenterModule),
			},
			{
				path: 'chat',
				loadChildren: () =>
					import(
						'./modules/broadcast-and-messaging/broadcast-and-messaging.module'
					).then((m) => m.BroadcastAndMessagingModule),
			},
			{
				path: 'courses',
				canActivate: [RoleGuard],
				data: {
					privileKey: MyCoursesModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import('./modules/my-courses/my-courses.module').then(
						(m) => m.MyCoursesModule
					),
			},
			{
				path: 'categories',
				canActivate: [RoleGuard],
				data: {
					privileKey:
						ResourceCategoriesModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import(
						'./modules/educational-categories/educational-categories.module'
					).then((m) => m.EducationalCategoriesModule),
			},
			{
				path: 'groups',
				canActivate: [RoleGuard],
				data: {
					privileKey: ResourceGroupsModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import(
						'./modules/educational-groups/educational-groups.module'
					).then((m) => m.EducationalGroupsModule),
			},
			{
				path: 'live-event',
				canActivate: [RoleGuard],
				data: {
					privileKey: LiveEventModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import('./modules/live-events/live-events.module').then(
						(m) => m.LiveEventsModule
					),
			},
			{
				path: 'surveys',
				canActivate: [RoleGuard],
				data: {
					privileKey: SurveysModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import('./modules/survey/survey.module').then(
						(m) => m.SurveyModule
					),
			},
			{
				path: 'survey-categories',
				canActivate: [RoleGuard],
				data: {
					privileKey: SurveysModulePrivilegesEnum.SURVEY_CATEGORY,
				},
				loadChildren: () =>
					import(
						'./modules/survey-categories/survey-categories.module'
					).then((m) => m.SurveyCategoriesModule),
			},
			{
				path: 'manage-users',
				canActivate: [RoleGuard],
				data: {
					privileKey: ManageUsersModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import('./modules/manage-users/manage-users.module').then(
						(m) => m.ManageUsersModule
					),
			},
			{
				path: 'profile',
				canActivate: [RoleGuard],
				data: {
					privileKey: MyProfileModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import('./modules/user-profile/user-profile.module').then(
						(m) => m.UserProfileModule
					),
			},
			{
				path: 'skillsAndCredentials',
				canActivate: [RoleGuard],
				data: {
					privileKey: SkillsModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import(
						'./modules/skills-and-credentials/skills-and-credentials.module'
					).then((m) => m.SkillsAndCredentialsModule),
			},
			{
				path: 'reports',
				canActivate: [RoleGuard],
				data: {
					privileKey: ReportModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import('./modules/reports/reports.module').then(
						(m) => ReportsModule
					),
			},
			{
				path: 'settings',
				loadChildren: () =>
					import('./modules/user-settings/user-settings.module').then(
						(m) => UserSettingsModule
					),
			},
			{
				path: 'user-access',
				data: {
					breadCrumb: [
						{
							title: 'HOME',
							url: 'dashboard',
							queryParams: '',
						},
						{
							title: 'USER_ACCESS',
							url: '',
							queryParams: '',
						},
					],
					privileKey: SettingsModulePrivilegesEnum.USER_ACCESS,
				},
				canActivate: [RoleGuard],
				resolve: { masterDatas: GetUserAccessSettingsResolver },
				component: UserAccessSettingsComponent,
			},
			{
				path: 'product-access',
				data: {
					breadCrumb: [
						{
							title: 'HOME',
							url: 'dashboard',
							queryParams: '',
						},
						{
							title: 'PRODUCT_ACCESS',
							url: '',
							queryParams: '',
						},
					],
					privileKey: SettingsModulePrivilegesEnum.PRODUCT_ACCESS,
				},
				canActivate: [RoleGuard],
				resolve: { masterDatas: GetProductAccessSettingsResolver },
				component: ProductAccessSettingsComponent,
			},
			{
				path: 'manage-codes',
				resolve: { codeList: ManageCodesListResolver },
				data: {
					breadCrumb: [
						{
							title: 'HOME',
							url: 'dashboard',
							queryParams: '',
						},
						{
							title: 'MANAGE_CODES',
							url: '',
							queryParams: '',
						},
					],
					privileKey: SettingsModulePrivilegesEnum.MANAGE_CODES,
				},
				canActivate: [RoleGuard],
				component: ManageCodeListComponent,
			},
			{
				path: 'manage-codes/add-new',
				data: {
					breadCrumb: [
						{
							title: 'HOME',
							url: 'dashboard',
							queryParams: '',
						},
						{
							title: 'MANAGE_CODES',
							url: '/manage-codes',
							queryParams: '',
						},
						{
							title: 'ADD_NEW_CODE',
							url: '',
							queryParams: '',
						},
					],
				},
				resolve: { masterData: AddNewCodeResolver },
				component: AddNewCodeComponent,
			},
			{
				path: 'qrm',
				canActivate: [RoleGuard],
				data: {
					privileKey: QRMModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import('./modules/qrm/qrm.module').then((m) => m.QrmModule),
			},
			{
				path: 'policy',
				canActivate: [RoleGuard],
				data: {
					privileKey:
						PolicyProfessionalModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import(
						'./modules/policy-professional/policy-professional.module'
					).then((m) => m.PolicyProfessionalModule),
			},
			{
				path: 'help-center',
				loadChildren: () =>
					import('./modules/help-system/help-system.module').then(
						(m) => m.HelpSystemModule
					),
			},
			{
				path: 'onboard-client',
				canActivate: [RoleGuard],
				data: {
					privileKey: OnboardClientsModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import(
						'./modules/onboard-clients/onboard-clients.module'
					).then((m) => m.OnboardClientsModule),
			},
			{
				path: 'system-tools',
				canActivate: [RoleGuard],
				data: {
					privileKey: SystemToolsModulePrivilegesEnum.MODULE_NAME,
				},
				loadChildren: () =>
					import('./modules/system-tools/system-tools.module').then(
						(m) => m.SystemToolsModule
					),
			},
			{
				path: '**',
				// component: DashboardComponent,
				pathMatch: 'full',
				redirectTo: 'dashboard',
			},
		],
		component: PrivateLayoutComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
