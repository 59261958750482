import { Injectable } from '@angular/core';
import {
	catchError,
	iif,
	map,
	mergeMap,
	Observable,
	of,
	switchMap,
	throwError,
} from 'rxjs';
import { FileLocationDetails } from '../models/file-upload.interface';
import { NotificationService } from './notification.service';
import { UrlNames } from './urlProfiler';
import { BaseWebApiService } from './_base-web-api.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { EncryptionService } from './encryption.service';
import { GenericHelper } from '../helpers/generic-helper.class';

@Injectable({
	providedIn: 'root',
})
export class FileUploadService {
	constructor(
		private apiService: BaseWebApiService,
		private notificationService: NotificationService,
		private http: HttpClient,
		private encryptionService: EncryptionService
	) {}
	public uploadFile(
		file: File,
		contentType: string,
		moduleName = '',
		isPublic: boolean = false
	): Observable<any> {
		let formData = new FormData();
		formData.append('file', file);
		return this.apiService
			.uploadFile(
				isPublic ? UrlNames.publicFileUpload : UrlNames.upload,
				formData,
				!isPublic && {
					contentType,
					moduleName,
				}
			)
			.pipe(
				catchError((err) => {
					if (err?.error?.status === '400') {
						this.notificationService.error(err?.error?.message);
					} else {
						this.notificationService.error(err.errorMessage);
					}
					return throwError(() => new Error(err));
				})
			);
	}

	public getPresignedUrl(
		path: string,
		certificateFileName?: string
	): Observable<FileLocationDetails> {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				return this.apiService
					.post(`${UrlNames.v2myCourses}${UrlNames.takeCourse}`, {
						path: this.encryptionService.encrypt(path, key),
						duration: this.encryptionService.encrypt('', key),
						certificateFileName: certificateFileName,
					})
					.pipe(map((res: FileLocationDetails) => res));
			})
		);
	}

	public getNonScormPresignedUrl(
		path: string
	): Observable<FileLocationDetails> {
		return this.apiService.get(`${UrlNames.nonScormPresignedUrl}`, {
			path,
			duration: null,
		});
	}

	private getPreSignedUrl(params: {
		contentType: string;
		moduleName: string;
		fileName: string;
		fileExtension: string;
		contentLength: string;
	}): Observable<any> {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				let encryptedParams = {
					contentType: this.encryptionService.encrypt(
						params.contentType,
						key
					),
					moduleName: this.encryptionService.encrypt(
						params.moduleName,
						key
					),
					fileName: this.encryptionService.encrypt(
						params.fileName,
						key
					),
					fileExtension: this.encryptionService.encrypt(
						params.fileExtension,
						key
					),
					contentLength: this.encryptionService.encrypt(
						params.contentLength,
						key
					),
				};
				return this.apiService.post(
					`${UrlNames.presignedUrl}`,
					encryptedParams
				);
			})
		);
	}

	public uploadToS3(file: File, moduleName: string = '') {
		const [name, ext] = GenericHelper.getNameAndExtension(file.name);
		return this.getPreSignedUrl({
			contentType: file.type,
			fileExtension: ext,
			fileName: name,
			moduleName,
			contentLength: file.size.toString(),
		}).pipe(
			switchMap((uri) => {
				const { filePath, url } = uri['data'][0];
				return this.http
					.put(url, file, {
						reportProgress: true,
						observe: 'events',
					})
					.pipe(
						mergeMap((res) =>
							iif(
								() => res.type == HttpEventType.UploadProgress,
								of(res),
								of({ ...res, body: filePath })
							)
						)
					);
			})
		);
	}
}
