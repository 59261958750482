import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';
@Component({
	selector: 'slr-password-tb',
	templateUrl: './slr-password-tb.component.html',
	styleUrls: ['./slr-password-tb.component.scss'],
})
export class SlrPasswordTbComponent implements OnInit {
	@Input() public control: FormControl;
	@Input() public placeholder: string;
	@Input() public label: string = 'Enter your password';
	@Input() public validatorMessages: ValidatorMessage[];
	public hide: boolean = true;
	public errorMessage: string;
	constructor(private readonly errorMessageService: ErrorMessageService) {}

	ngOnInit(): void {
		this.setErrorMessage();
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.validatorMessages,
			this.control
		);
	}
}
