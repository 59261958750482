import { Component, ElementRef, ViewChild } from '@angular/core';
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';

@Component({
	selector: 'app-password-pattern-message',
	templateUrl: './password-pattern-message.component.html',
	styleUrls: ['./password-pattern-message.component.scss'],
})
export class PasswordPatternMessageComponent {
	@ViewChild('pattern', { static: false, read: ElementRef })
	public myButtonRef: ElementRef;

	public passwordTooltipText = GenericHelper.passwordRequirementMessage;

	private matDialogRef: MatDialogRef<any>;

	constructor(private readonly matDialog: MatDialog) {}

	public showMessage(templateRef): void {
		const matDialogConfig = new MatDialogConfig();
		const rect: DOMRect =
			this.myButtonRef.nativeElement.getBoundingClientRect();
		const divWidth = 300;
		let divPosition = rect.right;
		const viewportWidth = window.innerWidth;
		if (divPosition + divWidth > viewportWidth) {
			divPosition = rect.right - divWidth + 20;
		}

		if (divPosition < 0) divPosition = 0;

		matDialogConfig.position = {
			left: `${divPosition}px`,
			top: `${rect.bottom + 2}px`,
		};

		this.matDialogRef = this.matDialog.open(templateRef, {
			width: `${divWidth}px`,
			hasBackdrop: false,
			panelClass: ['no-padding-popup'],
		});
		this.matDialogRef.updatePosition(matDialogConfig.position);
	}

	public closeMessage(): void {
		this.matDialogRef.close();
	}
}
