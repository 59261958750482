import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	ViewChild,
} from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { Subject, take, takeUntil } from 'rxjs';
import {
	ProfileSaveSkillInterface,
	ProfileSkillsInterface,
	UserSavedSkillInterface,
} from 'src/app/modules/user-profile/_models/profile-skills.interfaces';
import {
	addSkill,
	updateRemoveSkillArray,
} from 'src/app/modules/user-profile/_state/user-profile.actions';
import {
	getAllSkill,
	getUserSkill,
	UserProfileState,
} from 'src/app/modules/user-profile/_state/user-profile.reducer';
import { getUserId, SharedState } from 'src/app/shared/_state/shared.reducer';

@Component({
	selector: 'app-skills-table',
	templateUrl: './skills-table.component.html',
	styleUrls: [
		'./skills-table.component.scss',
		'../slr-table/slr-table.component.scss',
	],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition(
				'expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
			),
		]),
	],
})
export class SkillsTableComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) matSort: MatSort;

	@Optional() @Input() filterString: string = '';

	public allSkills: Array<ProfileSkillsInterface>;
	public _filteredSkills: Array<ProfileSkillsInterface>;
	public userSkills: Array<UserSavedSkillInterface>;
	public userId: number;

	@Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
	@Output() rowChecked: EventEmitter<any[]> = new EventEmitter();
	public selection = new SelectionModel<ProfileSaveSkillInterface>(true);
	public selectedIds = new SelectionModel<number>(true);

	public isManagedUsers: boolean = false;
	public newItems: Array<ProfileSaveSkillInterface>;

	private activeSort: string;
	private sortDirection: 'asc' | 'desc' = 'asc';
	private unsubscriber$ = new Subject<void>();

	constructor(
		private profileStore: Store<UserProfileState>,
		private sharedStore: Store<SharedState>,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.isManagedUsers = this.router.url.includes('manage-users');
		this.route.params.pipe(takeUntil(this.unsubscriber$)).subscribe({
			next: (res) => {
				let id = res['id'];
				if (id) this.userId = id;
				else {
					this.sharedStore
						.pipe(select(getUserId), take(1))
						.subscribe({
							next: (res) => (this.userId = res),
						});
				}
			},
		});
	}

	ngOnInit(): void {
		this.initColumns();
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}

	public get filteredSkills(): Array<ProfileSkillsInterface> {
		return !this.filterString || this.filterString.length == 0
			? this.allSkills
			: this.allSkills.filter((x) =>
					x.skillName
						?.toLowerCase()
						.includes(this.filterString.toLowerCase())
			  );
	}

	public initColumns(): void {
		this.profileStore
			.pipe(select(getAllSkill), takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					this.allSkills = res;
				},
			});
		this.profileStore
			.pipe(select(getUserSkill), takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					this.userSkills = res;
				},
			});
	}

	public emitRowAction(row: any): void {
		this.rowAction.emit(row);
	}

	public toggleSelection(event: any[]): void {
		if (event[0]) {
			this.addSkill(this.allSkills.find((x) => x.skillId == event[1]));
		} else {
			this.removeSkill(this.allSkills.find((x) => x.skillId == event[1]));
		}
	}

	public sortData(key: string): void {
		switch (this.sortDirection) {
			case 'asc':
				{
					this.allSkills = this.allSkills?.sort((a, b) => {
						return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
					});
					this.sortDirection = 'desc';
				}
				break;
			case 'desc': {
				this.allSkills = this.allSkills?.sort((a, b) => {
					return a[key] < b[key] ? 1 : a[key] > b[key] ? -1 : 0;
				});
				this.sortDirection = 'asc';
			}
		}
	}

	private addSkill(element: ProfileSkillsInterface): void {
		let obtainedDate = moment(new Date());
		let renewalDate = moment(new Date());
		let alertDate = moment(new Date());
		renewalDate.add(
			element.newRenewalFrequencyInYears == 0
				? element.defaultRenewalFrequency
				: element.newRenewalFrequencyInYears,
			'y'
		);
		alertDate = moment(renewalDate).subtract(
			element.defaultAlertsThresholdInDays
				? element.defaultAlertsThresholdInDays
				: 10,
			'd'
		);
		const newSkill: UserSavedSkillInterface = {
			skillId: element.skillId,
			isTemp: true,
			isUpdated: false,
			alertDate: alertDate.format('DD-MM-YYYY'),
			obatainedDate: obtainedDate.format('DD-MM-YYYY'),
			renewalDate: renewalDate.format('DD-MM-YYYY'),
			certificatePath: '',
			courseId: element.courseId,
			comments: null,
			skillCompetenceLevelId: element.useCompetency ? 1 : 0,
			lastUpdated: moment(new Date()).format('DD-MM-YYYY'),
			skillName: element.skillName,
			skillType: element.skillType,
			status: null,
			hasCertificates: false,
			hasComments: false,
			sessionTitle: null,
		};
		this.profileStore.dispatch(addSkill({ skill: newSkill }));
	}

	private removeSkill(element: ProfileSkillsInterface): void {
		this.profileStore.dispatch(
			// deleteSkill({
			// 	id: element.skillId,
			// 	userId: this.isManagedUsers ? this.userId : undefined,
			// })
			updateRemoveSkillArray({ id: element.skillId })
		);
	}
}
